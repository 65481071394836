import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from 'react-router-dom';
import "./styles.scss";
import { tagsData } from "../const";
import { getBlogsByTags } from "core/redux/actions/newBlogAction";
import useSiteTitle from 'core/hooks/useSiteTitle';
import { useTranslation } from "react-i18next";

const BlogTagsPage = () => {
    const { t } = useTranslation("common");
    useSiteTitle(t("blog_header"));
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const blogs = useSelector(state => state.newblog.blogs);

    // console.log("Tags no and name : ", id, tagsData[id]);
    console.log("blog list with tags: ", blogs);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getBlogsByTags(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (blogs.length > 0) {
            setLoading(false);
        }
    }, [blogs]);

    if (loading) {
        return <div>Loading...</div>;
    }
    return (
        <div className="card-page">
            <h1 className="page-title">{tagsData[id]}</h1>
            <div className="card-column">
                {blogs.map((card) => (
                    <div key={card.id} className="card">
                        <img
                            src={card.imgBackground}
                            alt={card.title}
                            className="card-image"
                        />
                        <div className="card-content">
                            <h3 className="card-title">{card.title}</h3>
                            <p className="card-date">Ngày viết: {new Date(card.createdAt).toLocaleDateString('vi-VN')}</p>
                            <div className="card-tags">
                                {card.tags.slice(0, 4).map((tag, index) => (
                                    <Link
                                        key={index}
                                        to={`/tin-tuc/tag/${tag}`}  // Adjust this path to your actual route structure
                                        className="card-tag"
                                    >
                                        {tagsData[tag]}
                                    </Link>
                                ))}
                            </div>
                        </div>
                        <Link
                            to={`/tin-tuc/${card.id}`}
                            className="see-more-button"
                        >
                            Xem Thêm
                        </Link>
                    </div>
                ))}
            </div>
        </div>

    );
};

export default BlogTagsPage;
