import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs, deleteBlog } from "core/redux/actions/newBlogAction";
import { Link } from "react-router-dom";
import "./styles/styles.scss";
function FixPost() {
    const dispatch = useDispatch();
    const blogs = useSelector(state => state.newblog.blogs);
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOption, setSortOption] = useState('date'); // Default sort by date
    const [loading, setLoading] = useState(true);

    // Fetch all blogs when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getAllBlogs());
    }, [dispatch]);

    // Update filtered blogs based on fetched blogs
    useEffect(() => {
        if (blogs.length > 0) {
            setFilteredBlogs(blogs);
            setLoading(false); // Set loading to false after data is fetched
        }
    }, [blogs]);

    // Handle search filtering
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        const filtered = blogs.filter(blog =>
            blog.title.toLowerCase().includes(query.toLowerCase()) ||
            blog.content.some(item =>
                item.content.toLowerCase().includes(query.toLowerCase())
            )
        );
        setFilteredBlogs(filtered);
    };

    // Handle sorting
    const handleSort = (e) => {
        const option = e.target.value;
        setSortOption(option);

        let sortedBlogs = [...filteredBlogs];

        if (option === 'date') {
            sortedBlogs = sortedBlogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by date descending
        } else if (option === 'title') {
            sortedBlogs = sortedBlogs.sort((a, b) => a.title.localeCompare(b.title)); // Sort by title alphabetically
        } else if (option === 'tags') {
            sortedBlogs = sortedBlogs.sort((a, b) => a.tags.length - b.tags.length); // Sort by number of tags (you can adjust the logic)
        }

        setFilteredBlogs(sortedBlogs);
    };

    // Handle blog deletion
    const handleDelete = (blogId) => {
        if (window.confirm("Are you sure you want to delete this post?")) {
            dispatch(deleteBlog(blogId)); // Make sure deleteBlog action is properly set up
        }
    };

    // Handle loading state
    if (loading) {
        return <div>Loading blogs...</div>;
    }

    return (
        <div className="blogs-page">
            <h1>Chỉnh Sửa Bài Viết</h1>

            {/* Search and Sort */}
            <div className="search-sort">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearch}
                    placeholder="Tìm kiếm bài viết..."
                />
                <select onChange={handleSort} value={sortOption}>
                    <option value="date">Sắp xếp theo ngày</option>
                    <option value="title">Sắp xếp theo tên</option>
                    <option value="tags">Sắp xếp theo tags</option>
                </select>
            </div>

            {/* Display filtered and sorted blogs */}
            <div className="blog-cards">
                {filteredBlogs.length > 0 ? (
                    filteredBlogs.map(blog => (
                        <div key={blog.id} className="blog-card">
                            <img
                                src={blog.imgBackground}
                                alt={blog.title}
                                className="blog-image"
                            />
                            <div className="blog-content">
                                <h3>{blog.title}</h3>
                                <p>{new Date(blog.createdAt).toLocaleDateString()}</p>

                                {/* Render blog content based on type */}
                                <div className="blog-text-content">
                                    {blog.content.map((contentItem, index) => {
                                        if (contentItem.type === "heading") {
                                            return <h4 key={index}>{contentItem.content}</h4>;
                                        }
                                        if (contentItem.type === "text") {
                                            return <p key={index}>{contentItem.content}</p>;
                                        }
                                        if (contentItem.type === "image") {
                                            return <img key={index} src={contentItem.content} alt="blog-content" />;
                                        }
                                        return null;
                                    })}
                                </div>

                                {/* Edit and Delete buttons */}
                                <div className="blog-actions">
                                    <Link to={`/admin/chinh-sua-bai-viet-chi-tiet/${blog.id}`} className="edit-button">
                                        Chỉnh sửa
                                    </Link>
                                    <button
                                        className="delete-button"
                                        onClick={() => handleDelete(blog.id)}
                                    >
                                        Xoá
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>Không tìm thấy blog</div>
                )}
            </div>
        </div>
    );
}

export default FixPost;
