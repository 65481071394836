import React from "react";
import { statusMessage } from "app/const/statusPayment";
import ZaloImg from "assets/images/ZALO.png";
import FBImg from "assets/images/facebook.jpeg";
import "./styles.scss";

function FailedTransaction({ status }) {
  const statusMgs = statusMessage.find((code) => code.code === status.RspCode);

  const SocialCard = ({ imgSrc, title, text, buttonText, url }) => (
    <div className="d-flex w-100 px-10">
      <div className="card-social">
        <img src={imgSrc} className="card-img-top" alt="Card cap" style={{ width: '80%', height: 'auto' }} />
        <div className="card-body" style={{ padding: '0.5rem' }}>
          <h4 className="card-title">{title}</h4>
          <p className="card-text" style={{ color: 'grey', marginTop: '5px' }}>{text}</p>
          <button className="btn btn-primary" style={{ width: '100%' }} onClick={() => handleSocialClick(url)}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );

  const handleSocialClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="wrapper-content container mb-5 mt-5">
      <i className="fal fa-times"></i>
      <div className="product">
        <div className="content-body_product">
          <h3 className="mt-4">Thanh toán của bạn thất bại</h3>
          <p className="mt-4">{statusMgs?.message}</p>
        </div>
      </div>

      <div className="container d-flex flex-column align-items-center mt-5">
        <div className="col-9 d-flex justify-content-center">
          <div className="row d-flex w-100" style={{ backgroundColor: "blue", alignItems: "center", height: "100%" }}>
            <p className="m-0 text-center" style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white", flex: 1, padding: "5px" }}>
              Hãy cùng tham gia nhóm cùng Vasanth Gopalan
            </p>
          </div>
        </div>

        <div className="container-social mt-10 d-flex">
          <SocialCard
            imgSrc={FBImg}
            title="Tham gia cộng đồng để cập nhật thông tin giá trị từ Chuyên gia"
            text="Nhóm Riêng Tư: 153.493 thành viên"
            buttonText="Tham Gia Nhóm"
            url="https://www.facebook.com/theoriginsacademy"
          />
          <SocialCard
            imgSrc={ZaloImg}
            title="Tham gia nhóm zalo để đăng ký và cập nhật thông tin"
            text="Nhóm Riêng Tư: 47.369 thành viên"
            buttonText="Tham Gia Nhóm"
            url="https://zalo.me/g/bqufiq446"
          />
        </div>
      </div>
    </div>
  );
}

export default FailedTransaction;
