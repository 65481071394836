import { orderVnpayReturn } from "core/redux/actions/orderAction";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Loading from "share/loading";
import SuccessTransaction from "../components/successTransaction";
import FailedTransaction from "../components/failedTransaction";
import { isEmpty } from "lodash";
import { getOrderDetails } from "core/redux/actions/orderAction";
function VNPayReturn() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  useEffect(() => {
    if (location.search) {
      const query = {};
      const queryStringWithoutQuestionMark = location.search.substring(1); // Remove the leading '?'
      const keyValuePairs = queryStringWithoutQuestionMark.split("&");

      keyValuePairs.forEach((pair) => {
        const [key, value] = pair.split("=");
        query[key] = value;
      });
      console.log("query", query);

      // Dispatch the orderVnpayReturn with query and setStatus as arguments
      dispatch(orderVnpayReturn(query, setStatus));
    }

    // Cleanup is unnecessary, React will handle the unmount itself
  }, [location.search, dispatch]);
  const renderbody = () => {
    console.log("status", status, "order", status.order);
    return status.RspCode !== "00" || status.order.status === "0" ? (
      <FailedTransaction status={status} />
    ) : (
      <SuccessTransaction order={status.order} />
    );
  };
  return isEmpty(status) ? <Loading /> : renderbody();
}

export default VNPayReturn;
