import {
    ADD_BLOG_REQUEST,
    ADD_BLOG_SUCCESS,
    ADD_BLOG_FAILED,
    GET_4_RECENT_BLOGS_REQUEST,
    GET_4_RECENT_BLOGS_FAILED,
    GET_4_RECENT_BLOGS_SUCCESS,
    GET_DETAIL_BLOG_FAILED,
    GET_DETAIL_BLOG_REQUEST,
    GET_DETAIL_BLOG_SUCCESS,
    GET_NEW_BLOGS_LIST_REQUEST,
    GET_NEW_BLOGS_LIST_SUCCESS,
    GET_NEW_BLOGS_LIST_FAILED,
    GET_BLOGS_BY_TAGS_REQUEST,
    GET_BLOGS_BY_TAGS_FAILED,
    GET_BLOGS_BY_TAGS_SUCCESS,
    DELETE_BLOG_REQUEST,
    DELETE_BLOG_SUCCESS,
    DELETE_BLOG_FAILED,
} from "core/redux/constant/newBlogConstant";

const initialState = {
    loading: false,
    blogs: [],
    specificBlog: [],
    recentBlogs: [],
    error: null,
};

export const newBlogReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_BLOG_REQUEST:
            return { ...state, loading: true };
        case ADD_BLOG_SUCCESS:
            return { ...state, loading: false, blog: action.payload, error: null };
        case ADD_BLOG_FAILED:
            return { ...state, loading: false, error: action.payload };
        case GET_4_RECENT_BLOGS_REQUEST:
            return { ...state, loading: true };
        case GET_4_RECENT_BLOGS_SUCCESS:
            return { ...state, loading: false, recentBlogs: action.payload };
        case GET_4_RECENT_BLOGS_FAILED:
            return { ...state, loading: false, recentBlogs: action.payload };
        case GET_NEW_BLOGS_LIST_REQUEST:
            return { ...state, loading: true };
        case GET_NEW_BLOGS_LIST_SUCCESS:
            return { ...state, loading: false, blogs: action.payload };
        case GET_NEW_BLOGS_LIST_FAILED:
            return { ...state, loading: false, blogs: action.payload };
        case GET_DETAIL_BLOG_REQUEST:
            return { ...state, loading: true };
        case GET_DETAIL_BLOG_SUCCESS:
            return { ...state, loading: false, specificBlog: action.payload };
        case GET_DETAIL_BLOG_FAILED:
            return { ...state, loading: false, specificBlog: null };
        case GET_BLOGS_BY_TAGS_REQUEST:
            return { ...state, loading: true };
        case GET_BLOGS_BY_TAGS_SUCCESS:
            return { ...state, loading: false, blogs: action.payload };
        case GET_BLOGS_BY_TAGS_FAILED:
            return { ...state, loading: false, blogs: action.payload };
        case DELETE_BLOG_REQUEST:
            return { ...state, loading: true };
        case DELETE_BLOG_SUCCESS:
            return {
                ...state,
                loading: false,
                blogs: state.blogs.filter(blog => blog._id !== action.payload), // Filter out the deleted blog
            };
        case DELETE_BLOG_FAILED:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};
