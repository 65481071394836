// import media file

import tr from "assets/images/source/course-offline/tr.jpg";
import sm from "assets/images/source/course-offline/sm.jpg";
import um from "assets/images/source/course-offline/um.jpg";
import icpc from "assets/images/source/course-offline/icpc.jpg";
import imcpc from "assets/images/source/course-offline/imcpc.jpg";
import gol from "assets/images/source/course-offline/gol.jpg";
import Combo from "assets/images/source/course-offline/Combo.jpg";
import LTC from "assets/images/source/course-offline/LTC.jpg";
import OAC from "assets/images/source/course-offline/OAC.jpg";
import mascotDragon from "assets/images/course/course-offline/mascot-dragon.png";
import mascotPhoenix from "assets/images/course/course-offline/mascot-phoenix.png";
import mascotUnicorn from "assets/images/course/course-offline/mascot-unicorn.png";
// import PAR from "assets/images/source/course-offline/PAR.jpg";
import level7 from "assets/images/course/course-offline/level7.png";
import level6 from "assets/images/course/course-offline/level6.png";
import level5 from "assets/images/course/course-offline/level5.png";
import level4 from "assets/images/course/course-offline/level4.png";
import level3 from "assets/images/course/course-offline/level3.png";
import level2 from "assets/images/course/course-offline/level2.png";
import level1 from "assets/images/course/course-offline/level1.png";

export const combo_phoenix = [
  {
    id: 1,
    name: "Khóa The Restart",
    // title: "the_restart",
    bgCourse: tr,
    textColor: "#fff",
    link: "/the-restart",
  },
  {
    id: 2,
    name: "khóa SM",
    // title: "self_mastery",
    bgCourse: sm,
    textColor: "#333",
    link: "/chuong-trinh-huan-luyen/offline/lam-chu-chinh-minh",
  },
  {
    id: 3,
    name: "Khoá UM",
    // title: "universal_mastery",
    bgCourse: um,
    textColor: "#fff",
    link: "/chuong-trinh-huan-luyen/offline/lam-chu-vu-tru-trong-ban",
  },
  {
    id: 4,
    name: "Khóa ICPC",
    // title: "icpc",
    bgCourse: icpc,
    textColor: "#fff",
    link: "/chuong-trinh-huan-luyen/offline/chung-nhan-quoc-te-tu-van-tri-lieu-chuyen-nghiep",
  },
];

export const combo_dragon = [
  {
    id: 9,
    name: "Combo Phoenix",
    title: "Các khóa Combo Phoenix",
    bgCourse: Combo,
    textColor: "#fff",
    link: "/chuong-trinh-huan-luyen/offline",
  },
  {
    id: 7,
    name: "khóa IMCPC",
    bgCourse: imcpc,
    textColor: "#fff",
    link: "/chuong-trinh-huan-luyen/offline/chung-nhan-quoc-te-master-coach-chuyen-nghiep",
  },

  {
    id: 8,
    name: "khóa GOL",
    bgCourse: gol,
    textColor: "#fff",
    link: "/chuong-trinh-huan-luyen/offline/chinh-phuc-tam-tri-co-the-cua-ban-than-va-lam-chu-cuoc-doi",
  },
];

export const otherCourse = [
  {
    id: 1,
    name: "Trại huấn luyện lãnh đạo",
    title: "Trại huấn luyện lãnh đạo",
    bgCourse: LTC,
    textColor: "#fff",
    link: "/chuong-trinh-huan-luyen/offline/trai-huan-luyen-lanh-dao",
  },
  {
    id: 2,
    name: "Trại thiếu niên xuất chúng",
    title: "Trại thiếu niên xuất chúng",
    bgCourse: OAC,
    textColor: "#fff",
    link: "/chuong-trinh-huan-luyen/offline/trai-thieu-nien-xuat-chung",
  },
  // {
  //   id: 3,
  //   name: "Nghệ thuật truyền cảm hứng & nuôi dạy con xuất chúng",
  //   title: "Nghệ thuật truyền cảm hứng & nuôi dạy con xuất chúng",
  //   bgCourse: PAR,
  //   textColor: "#fff",
  //   link: "/chuong-trinh-huan-luyen/offline/nghe-thuat-truyen-cam-hung-nuoi-day-con-xuat-chung",
  // },
];
export const ticketTRprice = [
  {
    title: "GENERAL",
    price: 299000,
  },
  {
    title: "PLATINUM",
    price: 1999000,
  },
  {
    title: "VIP",
    price: 2599000,
  },
]
export const courseOfflineForm = [
  {
    type: "courseOffline",
    name: "sm",
    course: "sm",
    img: sm,
    user: "",
    count: 1,
    price: 40000000,
    checkout: false,
  },
  {
    type: "courseOffline",
    name: "um",
    course: "um",
    img: um,
    user: "",
    count: 1,
    price: 50000000,
    checkout: false,
  },
  {
    type: "courseOffline",
    name: "icpc",
    course: "icpc",
    img: icpc,
    user: "",
    count: 1,
    price: 110000000,
    checkout: false,
  },
  {
    type: "courseOffline",
    name: "act",
    course: "act",
    img: icpc,
    user: "",
    count: 1,
    price: 35000000,
    checkout: false,
  },
  {
    type: "courseOffline",
    name: "imcpc",
    course: "imcpc",
    img: imcpc,
    user: "",
    count: 1,
    price: 160000000,
    checkout: false,
  },
  {
    type: "courseOffline",
    name: "gol",
    course: "gol",
    img: gol,
    user: "",
    count: 1,
    price: 70000000,
    checkout: false,
  },
  {
    type: "courseOffline",
    name: "tr",
    course: "tr",
    img: tr,
    user: "",
    count: 1,
    price: 0,
    checkout: false,
  },
  {
    type: "courseOffline",
    name: "combo_dragon",
    course: "combo_dragon",
    img: mascotDragon,
    user: "",
    count: 1,
    price: 198888000,
    checkout: false,
  },
  {
    type: "courseOffline",
    name: "combo_phoenix",
    course: "combo_phoenix",
    img: mascotPhoenix,
    user: "",
    count: 1,
    price: 105888000,
    checkout: false,
  },
  {
    type: "courseOffline",
    name: "combo_unicorn",
    course: "combo_unicorn",
    img: mascotUnicorn,
    user: "",
    count: 1,
    price: 60888000,
    checkout: false,
  },
];

export const arrayCourseOffline = [
  {
    img: level1,
    headEng: "SELF MASTERY",
    headVie: "LÀM CHỦ TƯ DUY",
    description:
      "Chương trình học nhằm đưa học viên vào một hành trình toàn diện, nhằm giúp họ chữa lành mối quan hệ với chính bản thân mình và những mối quan hệ xung quanh, đồng thời xây dựng lối sống lành mạnh và tích cực.",
    price: 40000000,
    url: "/chuong-trinh-huan-luyen/offline/lam-chu-chinh-minh",
  },
  {
    img: level2,
    headEng: "UNIVERSAL MASTERY",
    headVie: "CHINH PHỤC THÀNH CÔNG",
    description:
      "Chương trình sẽ giúp bạn THIẾT LẬP và HÀNH XỬ 23 TƯ DUY của những người xuất chúng trên thế giới này, vượt qua nỗi sợ hãi để biến cuộc đời trở thành kiệt tác.",
    price: 50000000,
    url: "/chuong-trinh-huan-luyen/offline/lam-chu-vu-tru-trong-ban",
  },
  {
    img: level3,
    headEng: "INTERNATIONAL COACH",
    headEng2: "PROFESSIONAL CERTIFICATION",
    headVie: "CHỨNG NHẬN QUỐC TẾ TƯ VẤN",
    headVie2: "TRỊ LIỆU CHUYÊN NGHIỆP",
    description:
      "Xây dựng ước mơ huấn luyện giúp đỡ những người khác có được sự rõ ràng và tập trung trong cuộc sống của họ, giải quyết những thách thức cá nhân và nghề nghiệp, và xây dựng một cuộc sống viên mãn hơn.",
    price: 110000000,
    url: "/chuong-trinh-huan-luyen/offline/chung-nhan-quoc-te-tu-van-tri-lieu-chuyen-nghiep",
  },
  {
    img: level4,
    headEng: "ADVANCE COACHING TRAINING",
    headVie: "COACHING CHUYÊN SÂU",
    description: "COACHING CHUYÊN SÂU 01",
    price: 35000000,
    url: "/hop-tac/tu-van-tri-lieu-ca-nhan",
  },
  {
    img: level5,
    headEng: "INTERNATIONAL MASTER COACH",
    headEng2: "PROFESSIONAL CERTIFICATION",
    headVie: "CHỨNG NHẬN QUỐC TẾ ",
    headVie2: "MASTER COACH CHUYÊN NGHIỆP",
    price: "160000000",
    description:
      "Thực hiện Bước đầu tiên Hướng tới Sự nghiệp trở thành nhà tư vấn và nhận khách hàng bất kỳ đâu trên toàn quốc. Tận dụng phương pháp đã được chứng minh của ICPC để trở thành nhà huấn luyện thành công",
    url: "/chuong-trinh-huan-luyen/offline/chung-nhan-quoc-te-master-coach-chuyen-nghiep",
  },
  {
    img: level6,
    headEng: "GRADUATE OF LIFE",
    headVie: "TỐT NGHIỆP CUỘC ĐỜI",
    price: 70000000,
    description:
      "Chinh phục tâm trí và cơ thể của bạn và làm chủ cuộc đời của một Master NLP",
    url: "/chuong-trinh-huan-luyen/offline/chinh-phuc-tam-tri-co-the-cua-ban-than-va-lam-chu-cuoc-doi",
  },
  {
    img: level7,
    headEng: "ADVANCE COACHING TRAINING 02",
    headVie: "COACHING CHUYÊN SÂU 02 ",
    price: 35000000,
    description: "COACHING CHUYÊN SÂU 02 ",
    url: "/hop-tac/tu-van-tri-lieu-ca-nhan",
  },
];

export const arrayMountain = [
  {
    title: "Fansipan",
    mountainTag: "mountain",
  }
]