import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addBlog } from "core/redux/actions/newBlogAction";
import "./styles/index.scss";
import { tagsData } from "app/modules/ClientModules/const";

function AddPost() {
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [imgBackground, setImgBackground] = useState("");
  const [content, setContent] = useState([{ type: "heading", content: "", color: "#000000" }]);
  const [tags, setTags] = useState([]);
  const [standOut, setStandOut] = useState(false);
  const dispatch = useDispatch();
  const blogState = useSelector((state) => state.blog);
  const { loading, error, blog } = blogState;

  const handleSectionChange = (index, field, value) => {
    const updatedContent = [...content];
    updatedContent[index][field] = value;
    setContent(updatedContent);
  };

  const addSection = () => {
    setContent([...content, { type: "heading", content: "", color: "#000000" }]);
  };

  const removeSection = (index) => {
    const updatedContent = content.filter((_, i) => i !== index);
    setContent(updatedContent);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const blogData = {
      title,
      id,
      imgBackground,
      content: content.map((item) => ({
        ...item,
        color: item.color || "#000000" // Ensure default color is black if empty
      })),
      tags,
      standOut,
    };
    dispatch(addBlog(blogData));
  };

  return (
    <div>
      <h1>Thêm Bài Viết</h1>
      <form onSubmit={handleSubmit} className="blog-form-container">
        <div className="form-group">
          <label>Tiêu Đề Tổng:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            placeholder="Tiêu Đề Bài Viết"
          />
        </div>
        <div className="form-group">
          <label>Id:</label>
          <input
            type="text"
            value={id}
            onChange={(e) => setId(e.target.value)}
            required
            placeholder="Nhập ID"
          />
        </div>
        <div className="form-group">
          <label>Hình Background Tổng:</label>
          <input
            type="text"
            value={imgBackground}
            onChange={(e) => setImgBackground(e.target.value)}
            required
            placeholder="Link hình background"
          />
        </div>
        <div className="form-group">
          <label>Gắn thẻ:</label>
          <div className="tags-group">
            {tagsData.map((tag, index) => (
              <div key={index} className="form-check">
                <input
                  type="checkbox"
                  id={`tag-${index}`}
                  value={index}
                  checked={tags.includes(index)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTags((prevTags) => [...prevTags, index]); // Add tag
                    } else {
                      setTags((prevTags) => prevTags.filter((t) => t !== index)); // Remove tag
                    }
                  }}
                />
                <label htmlFor={`tag-${index}`}>{tag}</label>
              </div>
            ))}
          </div>
        </div>

        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={standOut}
              onChange={(e) => setStandOut(e.target.checked)}
            />
            Đánh dấu nổi bật
          </label>
        </div>

        <h3>Nội Dung</h3>
        <div className="section-list">
          {content.map((section, index) => (
            <div className="section-item" key={index}>
              <select
                value={section.type}
                onChange={(e) => handleSectionChange(index, "type", e.target.value)}
                required
              >
                <option value="heading">Tiêu đề 1</option>
                <option value="heading2">Tiêu đề 2</option>
                <option value="heading3">Tiêu đề 3</option>
                <option value="text">Văn bản</option>
                <option value="image">Đường dẫn hình ảnh</option>
                <option value="imgNote">Chú thích ảnh</option>
              </select>

              <input
                type="text"
                placeholder="Content"
                value={section.content}
                onChange={(e) => handleSectionChange(index, "content", e.target.value)}
                required
              />

              <input
                type="text"
                placeholder="Hex Color (#000000)"
                value={section.color}
                onChange={(e) => handleSectionChange(index, "color", e.target.value)}
              />

              <button
                type="button"
                onClick={() => removeSection(index)}
                className="remove-section-button"
              >
                Xóa
              </button>
            </div>
          ))}
        </div>


        <button type="button" onClick={addSection} className="add-section-button">
          Thêm
        </button>
        <button type="submit" className="submit-button">
          Hoàn Thành
        </button>
      </form>
    </div>
  );
}

export default AddPost;
