export const tagsData = [
    'Chuỗi Khóa Học The Origins',
    'Câu Chuyện Học Viên',
    'Bài Học Từ Mr Vas',
    'Sự Kiện Nổi Bật',
  ];
  
  export const membershipOverviewData = [
    {
      id: 1,
      title: "NỘI DUNG SẢN PHẨM/PRODUCT",
      smallTitle: "KHÓA HỌC/COURSE",
      content: [
        "Khóa combo Dragon - Dành cho hội viên mới tham gia CLB, giúp họ nắm vững các kiến thức và kỹ năng cơ bản/Dragon combo course - For new members joining the club, helping them master basic knowledge and skills",
        "Khóa huấn luyện Trainer - Trainer training course",
        "Khóa huấn luyện Thôi miên chuyên sâu - Intensive Hypnosis Training",
      ],
    },
    {
      id: 2,
      smallTitle: "COACHING 1:1",
      special: ["Cô La Hạ Giang Thanh - 12 giờ Coaching",
                "Thầy Vasanth Gopalan - 8 giờ Coaching"
            ],
      content: [
        "Sức khỏe/Health perfect",
        "Mối quan hệ/Relationship",
        "Nâng cấp tài chính/Financial upgrades",
        "Ngoài các vấn đề khác/Other issues",
      ],
    },
    {
      id: 3,
      smallTitle: "HOẠT ĐỘNG NGOẠI KHÓA CHỮA LÀNH/ OUTDOOR ACTIVITIES",
      reminder: ["Note: Lựa chọn sử dụng khóa Chinh phục Fansipan hoặc Chinh phục Kinabalu; nếu sử dụng cả 02 thì sẽ được giá ưu đãi cho thành viên Dragon",
                ],
      challenge: [
        "Chinh phục đỉnh Fansipan",
        "Chinh phục đỉnh Kinabalu",
      ],
    },
    {
      id: 4,
      smallTitle: "VÉ THAM DỰ CHƯƠNG TRÌNH THE RESTART/ TICKETS TO THE RESTART PROGRAM",
    },
    {
      id: 5,
      smallTitle: "HOẠT ĐỘNG ĐỊNH KỲ CỦA CÂU LẠC BỘ DRAGON (ACTIVITIES OF DRAGON CLUB)",
      // you can keep the 'reminder' array if you still want that subtext
      reminder: [
        "Offline 4 buổi: Các buổi offline giúp hội viên kết nối trực tiếp và trao đổi kinh nghiệm.",
        "Offline 4 sessions: Offline sessions help members networking",
        "6 buổi thực hành coaching cho nhau - 6 coaching practice sessions for each other",
      ],
      content: [
        "Hành trình chinh phục mục tiêu - Journey to conquer goals",
        "Dự án thiện nguyện Origins Academy- Origins Academy Charity Project",
        "Học quy trình đặc biệt chỉ dành cho thành viên câu lạc bộ Dragon - Learn special progress only for Dragon members",
        "Year-end party and New Year",
        "12 buổi online - 12 online sessions",
        "Bộ sách Obsessed + video hướng dẫn thực hành (108 ngày) - Document of OBSESSED + video tutorial (108 days)",
      ],
    },
    {
      id: 6,
      smallTitle: "ƯU ĐÃI QUYỀN LỢI KHÁC CHO THÀNH VIÊN CÂU LẠC BỘ DRAGON/OTHER BENEFITS FOR DRAGON CLUB MEMBERS",
      highlight: [
        "Ưu đãi học lại các khóa học thuộc chuỗi Dragon (10% phí tham dự chương trình)",
        "Cơ hội trở thành người đồng hành trung tâm trị liệu Leonardo Therapy - Opportunity to become a associate coach of Leonardo Therapy",
        "Cơ hội trờ thành chủ tịch CLB, phó chủ tịch CLB, phó chủ tịch học tập, chủ tịch truyền thông - Opportunity to become club president, club vice president academic vice president, communications president",
        "Ưu đãi giảm 20% khi đăng ký khóa (Thiên tài tương lai) - 20% discount when registering for the course (Kid Program)",
        "Ưu đãi khi đăng ký các khóa học chuyên sâu tại Soul Retreats (10%) - Discount when registering for intensive courses, other courses at Soul Retreats 10%",
      ],
    },
  ];
  