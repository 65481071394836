export const API_VERSION = "/v1";

export const API_ENDPOINT =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND_URL
    : process.env.REACT_APP_BACKEND_URL_ONLINE;
export const SIGN_IN = "/auth/sign-in";
export const SIGN_UP = "/auth/sign-up";
export const FORGOT_PASSWORD = "/auth/forgot-password";
export const VERIFY_REGISTER = "/auth/verify-sign-up";
export const GET_USER_WITH_TOKEN = "/auth/fetch-with-token";
export const VERIFY_FORGET_PASSWORD = "/auth/verify-forgot-password";
export const CHANGE_PASSWORD = "/auth/change-password";
export const CATEGORY = "/category";

//core

// users
export const USERS = "/users/";
export const TOP_USERS = "/users/get-top-ten/";
export const USERS_SEARCH = "/users/search/";
export const USER_UPDATE = "/users/client/";
export const USER_SENDFORMCONTACT = "/users/send-form-contact/";
export const ADMIN_UPDATE = "/users/admin/";
export const UPLOAD_AVATAR = "/users/upload-avatar";
export const REMOVE_AVATAR = "/users/delete-avatar";

//  admin STUDENT
export const API_GET_STUDENT = "/student/";
export const API_STUDENT_CREATE = "/student/create/";
export const API_STUDENT_CREATE_ARRAY = "/student/create-array/";
export const API_STUDENT_SEARCH = "/student/search/";
export const API_STUDENT_UPDATE = "/student/update/";
export const API_STUDENT_DELETE = "/student/delete/";

// admin course
export const API_GET_COURSE = "/course/";
export const API_COURSE_CREATE = "/course/create/";
export const API_COURSE_CREATE_ARRAY = "/course/create-array/";
export const API_COURSE_SEARCH = "/course/search/";
export const API_COURSE_UPDATE = "/course/update/";
export const API_COURSE_DELETE = "/course/delete/";

// admin courseOnline
export const API_GET_DETAIL_COURSE_ONLINE = "/course-online/detail/";
export const API_GET_COURSE_ONLINE = "/course-online/";
export const API_COURSE_ONLINE_CREATE = "/course-online/create/";
export const API_COURSE_ONLINE_CREATE_ARRAY = "/course-online/create-array/";
export const API_COURSE_ONLINE_SEARCH = "/course-online/search/";
export const API_COURSE_ONLINE_UPDATE = "/course-online/update/";
export const API_COURSE_ONLINE_DELETE = "/course-online/delete/";

// admin scheduleCourse

// export const API_GET_SCHEDULE_COURSE = "/schedule-course/";
// export const API_SCHEDULE_COURSE_CREATE = "/schedule-course/create/";
// export const API_SCHEDULE_COURSE_CREATE_ARRAY =
//   "/schedule-course/create-array/";
// export const API_SCHEDULE_COURSE_SEARCH = "/schedule-course/search/";
// export const API_SCHEDULE_COURSE_UPDATE = "/schedule-course/update/";
// export const API_SCHEDULE_COURSE_DELETE = "/schedule-course/delete/";

// admin scheduleCourse

export const API_GET_DETAILS_SCHEDULE_COURSE_ONLINE =
  "/schedule-course-online/detail/";
export const API_GET_SCHEDULE_COURSE_ONLINE = "/schedule-course-online/";
export const API_SCHEDULE_COURSE_ONLINE_CREATE =
  "/schedule-course-online/create/";
export const API_SCHEDULE_COURSE_ONLINE_CREATE_ARRAY =
  "/schedule-course-online/create-array/";
export const API_SCHEDULE_COURSE_ONLINE_SEARCH =
  "/schedule-course-online/search/";
export const API_SCHEDULE_COURSE_ONLINE_UPDATE =
  "/schedule-course-online/update/";
export const API_SCHEDULE_COURSE_ONLINE_HANDLE_USER =
  "/schedule-course-online/handle-user/";
export const API_SCHEDULE_COURSE_ONLINE_HANDLE_USER_CHANGE_PERMISSION =
  "/schedule-course-online/handle-user-change-permission/";
export const API_SCHEDULE_COURSE_ONLINE_HANDLE_ADD_USER_TO_SCHEDULE =
  "/schedule-course-online/handle-add-user-to-schedule/";
export const API_SCHEDULE_COURSE_ONLINE_HANDLE_DEL_USER_FROM_SCHEDULE =
  "/schedule-course-online/handle-del-user-from-schedule/";
export const API_SCHEDULE_COURSE_ONLINE_HANDLE_USER_ANSWER_QUESTION =
  "/schedule-course-online/handle-user-answer-question/";

export const API_SCHEDULE_COURSE_ONLINE_DELETE =
  "/schedule-course-online/delete/";
export const API_SCHEDULE_COURSE_ONLINE_SEND_EMAIL_AFTER_ANSWER =
  "/schedule-course-online/send-email-after-answer/";
// admin question

export const API_GET_QUESTION = "/question/";
export const API_QUESTION_CREATE = "/question/create/";
export const API_QUESTION_CREATE_ARRAY = "/question/create-array/";
export const API_QUESTION_SEARCH = "/question/search/";
export const API_QUESTION_UPDATE = "/question/update/";
export const API_QUESTION_DELETE = "/question/delete/";

// admin Video
export const API_GET_VIDEO_DETAILS = "/video/details/";
export const API_GET_VIDEO = "/video/";
export const API_VIDEO_CREATE = "/video/create/";
export const API_VIDEO_CREATE_ARRAY = "/video/create-array/";
export const API_VIDEO_SEARCH = "/video/search/";
export const API_VIDEO_UPDATE = "/video/update/";
export const API_VIDEO_DELETE = "/video/delete/";

// BLOG
export const UPLOAD_IMAGE_BLOG = "/blog/upload-image-blog";

// TOPIC
export const TOPIC = "/topic/";

// Blog
export const BLOG = "/blog/";
export const CREATE_BLOG = "/blog/create-post";

// New Blog
export const NEW_BLOG = "/newblog";
export const CREATE_NEW_BLOG = "/newblog/create";
export const GET_SPECIFIC_BLOG = "/newblog/get-blog/";
export const GET_4_RECENT_BLOGS = "/newblog/get-recent-4-blogs";
export const GET_BLOGS_BY_TAGS = "/newblog/get-blog/tags/";
export const DELETE_BLOG_BY_ID = "/newblog/delete/";
export const UPDATE_BLOG_BY_ID = "/newblog/update/";

// Code status
export const CODE_SUCCESS = 200;
export const CODE_FAIL = 202;
export const CODE_FAILURE = 403;
export const CODE_FAILURE_AUTHENTICATION = 404;

// ORDER
export const API_ORDER_GET_LIST = "/order/list";
export const API_ORDER_GET_DETAILS = "/order/details";
export const API_ORDER_CREATE_PAYMENT_URL = "/order/create_payment_url";
export const API_ORDER_VNPAY_RETURN = "/order/vnpay_return";
export const API_ORDER_VNPAY_IPN = "/order/vnpay_ipn";
export const API_ORDER_QUERYDR_VNPAY = "/order/querydr_vnpay";
