import React from "react";
import "./styles.scss"; // SCSS styles shown below

const SeeMoreButton = () => {
  return (
    <button className="see-more-btn">
      SEE MORE
      <span className="arrow">{/* Right arrow */}→</span>
    </button>
  );
};

export default SeeMoreButton;
