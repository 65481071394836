// TimeTable.js
import React, { useState } from "react";
import "./styles.scss";

const TimeTable = ({ coachName }) => {
  // Some dummy time slots (replace with real data if needed)
  const timeSlots = [
    "09:00 - 10:00 AM",
    "10:00 - 11:00 AM",
    "01:00 - 02:00 PM",
    "02:00 - 03:00 PM",
    "03:00 - 04:00 PM",
  ];

  // State to track which slot is selected
  const [selectedSlot, setSelectedSlot] = useState(null);

  // For demonstration only:
  // If you wanted to "book" or "confirm" the slot, you could call an API etc.
  const handleConfirm = () => {
    alert(`You have booked: ${coachName}\nTime: ${selectedSlot}`);
  };

  return (
    <div className="time-table">
      <h4>Select Time Slot</h4>
      <div className="time-slot-list">
        {timeSlots.map((slot, index) => (
          <button
            key={index}
            className={`slot-button ${
              selectedSlot === slot ? "selected" : ""
            }`}
            onClick={() => setSelectedSlot(slot)}
          >
            {slot}
          </button>
        ))}
      </div>

      {selectedSlot && (
        <div className="selected-slot">
          <p>Selected Slot: {selectedSlot}</p>
          <button className="confirm-button" onClick={handleConfirm}>
            Confirm Booking
          </button>
        </div>
      )}
    </div>
  );
};

export default TimeTable;
