import React from "react";
import "./styles.scss"; // Create & style this SCSS file

const FlagMembership = () => {
  return (
    <div className="ticket-section">
      {/* Optional heading if you want it inside the component */}
      <h2 className="ticket-section-title">VÉ THAM DỰ CHƯƠNG TRÌNH THE RESTART</h2>

      <div className="flag-container">
        {/* First flag */}
        <div className="flag">
          <div className="flag-top">TẶNG</div>
          <div className="flag-number">12</div>
          <div className="flag-bottom vip">VIP</div>
        </div>

        {/* Second flag */}
        <div className="flag">
          <div className="flag-top">TẶNG</div>
          <div className="flag-number">24</div>
          <div className="flag-bottom gen">GEN</div>
        </div>
      </div>

      {/* Optional sub-note */}
      <p className="ticket-note">02 vé VIP cho mỗi chương trình</p>
    </div>
  );
};

export default FlagMembership;
