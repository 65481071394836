import React from "react";
import "./styles.scss";
import kickstartimage from "assets/images/source/homepage/kickstartimage.png";
// import QRScanCode from "./components/QRScanCode";
const EventDetail = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        {/* Left Column: Event Information */}
        <div className="col-md-6 d-flex flex-column align-items-center text-center text-white">
          <div className="event-image mb-4">
            <img
              src={kickstartimage} // Use the imported image here
              alt="Event"
              className="img-fluid rounded"
            />
          </div>
          <div className="text-uppercase fw-bold text-warning custom-container-kickstart">
            <h2 style={{ color: "#985858" }} className="fw-bold">Kickstart to New Beginnings 2025 </h2>
              <i className="fas fa-calendar-alt me-2"></i>
              <p style={{ color: "#9c990bd5" }}>19/01/2025 | 09:00 - 19:00</p>
              <i  className="fas fa-map-marker-alt me-2"></i> 
              <p style={{ color: "#9c990bd5" }}> Hotel Majestic Saigon</p>
              <i  className="fas fa-tshirt me-2"></i> 
              <p style={{ color: "#9c990bd5" }}> Trang phục: Sang trọng & Thanh lịch</p>
          </div>
          <div className="message-box bg-warning text-dark p-3 mt-3 rounded">
            <h5 className="fw-bold">Thân gửi Học Viên</h5>
            <p>
              Soul Retreats trân trọng kính mời Quý Anh/Chị tham dự sự kiện tại{" "}
              <b>Hotel Majestic Saigon</b>, để chúng ta cùng nhau nâng ly nhìn
              lại những thành tựu trong một năm vừa qua, kiến tạo thêm cho năm
              mới những điều tốt đẹp và tuyệt vời hơn.
            </p>
          </div>
        </div>

        {/* Right Column: Program Content */}
              <div className="col-md-6">
            <div className="bg-danger text-white p-4 rounded program-box">
              <h3 className="fw-bold text-center mb-4">NỘI DUNG CHƯƠNG TRÌNH</h3>
              <ul className="list-group list-group-flush">
                <li className="list-group-item bg-transparent text-white d-flex align-items-start">
                  <span className="badge bg-warning text-dark me-3">1</span>
                  Tham quan khu vực trưng bày và chụp ảnh lưu niệm
                </li>
                <li className="list-group-item bg-transparent text-white d-flex align-items-start">
                  <span className="badge bg-warning text-dark me-3">2</span>
                  Câu chuyện truyền cảm hứng từ học viên
                </li>
                <li className="list-group-item bg-transparent text-white d-flex align-items-start">
                  <span className="badge bg-warning text-dark me-3">3</span>
                  Bài học Khai Mở Ý Tưởng Cho Năm 2025 dưới sự dẫn dắt của Chuyên gia
                  Vasanth Gopalan
                </li>
                <li className="list-group-item bg-transparent text-white d-flex align-items-start">
                  <span className="badge bg-warning text-dark me-3">4</span>
                  Quy trình Bứt Phá Giới Hạn dưới sự dẫn dắt của Chuyên gia Tâm Lý La
                  Hạ Giang Thanh
                </li>
                <li className="list-group-item bg-transparent text-white d-flex align-items-start">
                  <span className="badge bg-warning text-dark me-3">5</span>
                  Chương trình đấu giá “Lộc Xuân Từ Tấm Lòng” quyền góp cho các trẻ em
                  khu vực khó khăn ở Việt Nam
                </li>
                <li className="list-group-item bg-transparent text-white d-flex align-items-start">
                  <span className="badge bg-warning text-dark me-3">6</span>
                  Hỏi & Đáp cùng Chuyên gia
                </li>
                <li className="list-group-item bg-transparent text-white d-flex align-items-start">
                  <span className="badge bg-warning text-dark me-3">7</span>
                  The Origins Award
                </li>
                <li className="list-group-item bg-transparent text-white d-flex align-items-start">
                  <span className="badge bg-warning text-dark me-3">8</span>
                  Chương trình văn nghệ mừng năm mới 2025
                </li>
              </ul>
              <div className="mt-4 text-center">
                <p>🎁 Nhận 1 Vé Platinum Tham Gia The Restart</p>
                <p>🍴 Ăn Buffet Trưa</p>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-4">
                <p className="mb-0">
                  📞 <b>028 3535 4859</b>
                </p>
                <div className="d-flex align-items-center">
                  <i
                    className="fab fa-facebook fa-lg me-2 text-white"
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                  <span className="text-white">theoriginsacademy</span>
                </div>
              </div>
              <div className="text-center mt-4">
                {/* Use the QRScanCode component */}
                {/* <QRScanCode /> */}
                <p className="mt-2">Scan để đăng ký ngay</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default EventDetail;
