import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSpecificBlog, updateBlog } from "core/redux/actions/newBlogAction";
import { useParams, useNavigate } from "react-router-dom";
import "./styles/styles.scss";
import { tagsData } from "app/modules/ClientModules/const";
function FixPostDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [tags, setTags] = useState([])
    const [blogData, setBlogData] = useState({
        title: "",
        imgBackground: "",
        content: [{ type: "heading", content: "" }],
        tags: [],
        standOut: false,
    });
    const [loading, setLoading] = useState(true);

    const specificBlog = useSelector((state) => state.newblog.specificBlog);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSpecificBlog(id));
    }, [id, dispatch]);

    useEffect(() => {
        if (specificBlog && Object.keys(specificBlog).length > 0) {
            setBlogData({
                title: specificBlog.title || "",
                imgBackground: specificBlog.imgBackground || "",
                content: specificBlog.content || [],
                tags: specificBlog.tags || [],
                standOut: specificBlog.standOut || false,
            });
            setTags(specificBlog.tags)
            setLoading(false);
        }
    }, [specificBlog]);

    const handleSectionChange = (index, field, value) => {
        const newContent = [...blogData.content];
        newContent[index][field] = value;
        setBlogData((prevData) => ({
            ...prevData,
            content: newContent,
        }));
    };

    const addSection = () => {
        setBlogData((prevData) => ({
            ...prevData,
            content: [
                ...prevData.content,
                { type: "heading", content: "" }, // Add a default section (heading in this case)
            ],
        }));
    };

    const removeSection = (index) => {
        const newContent = blogData.content.filter((_, i) => i !== index);
        setBlogData((prevData) => ({
            ...prevData,
            content: newContent,
        }));
    };

    const handleTagsChange = (e) => {
        const newTags = e.target.value.split(",").map((tag) => tag.trim());
        setBlogData((prevData) => ({
            ...prevData,
            tags: newTags,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedBlogData = {
            title: blogData.title,
            imgBackground: blogData.imgBackground,
            content: blogData.content,
            tags: tags,
            standOut: blogData.standOut,
        };
        console.log("Blogdata : ", updatedBlogData);
        dispatch(updateBlog(id, updatedBlogData))
            .then(() => {
                alert("Blog updated successfully!");
                navigate("/blogs");
            })
            .catch((error) => {
                console.error("Error updating blog:", error);
            });
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            <h1>Chỉnh Sửa Blog</h1>
            <form onSubmit={handleSubmit} className="justify-content-center">
                <div>
                    <label>Tiêu Đề</label>
                    <input
                        type="text"
                        value={blogData.title}
                        onChange={(e) =>
                            setBlogData((prevData) => ({
                                ...prevData,
                                title: e.target.value,
                            }))
                        }
                    />
                </div>
                <div>
                    <label>Hình Nền Tổng</label>
                    <input
                        type="text"
                        value={blogData.imgBackground}
                        onChange={(e) =>
                            setBlogData((prevData) => ({
                                ...prevData,
                                imgBackground: e.target.value,
                            }))
                        }
                    />
                </div>

                <div className="section-list">
                    {blogData.content.map((section, index) => (
                        <div className="section-item" key={index}>
                            <div className="section-input-container">
                                <select
                                    value={section.type}
                                    onChange={(e) => handleSectionChange(index, "type", e.target.value)}
                                    required
                                >
                                    <option value="heading">Tiêu Đề</option>
                                    <option value="text">Văn Bản</option>
                                    <option value="image">Link Hình Ảnh</option>
                                    <option value="imgNote">Chú Thích Hình Ảnh</option>
                                </select>

                                <input
                                    type="text"
                                    placeholder="Content"
                                    value={section.content}
                                    onChange={(e) => handleSectionChange(index, "content", e.target.value)}
                                    required
                                />

                                <button
                                    type="button"
                                    onClick={() => removeSection(index)}
                                    className="remove-section-button"
                                >
                                    Xóa
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <button
                    type="button"
                    onClick={addSection}
                    className="add-section-button"
                    style={{ marginBottom: "10px" }}
                >
                    Thêm
                </button>
                <div className="tags-group">
                    {tagsData.map((tag, index) => (
                        <div key={index} className="form-check">
                            <input
                                type="checkbox"
                                id={`tag-${index}`}
                                value={index}
                                checked={tags.includes(index)} // Check if this tag index is selected
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setTags((prevTags) => [...prevTags, index]); // Add tag index
                                    } else {
                                        setTags((prevTags) => prevTags.filter((t) => t !== index)); // Remove tag index
                                    }
                                }}
                            />
                            <label htmlFor={`tag-${index}`}>{tag}</label>
                        </div>
                    ))}
                </div>



                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={blogData.standOut}
                            onChange={(e) =>
                                setBlogData((prevData) => ({
                                    ...prevData,
                                    standOut: e.target.checked,
                                }))
                            }
                        />
                        Nổi Bật
                    </label>
                </div>
                <button type="submit">Lưu Thay Đổi</button>
            </form>
        </div>
    );
}

export default FixPostDetail;
