// src/app/components/MembershipBenefits.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const MembershipBenefits = () => {
  const { t } = useTranslation('common');

  return (
    <div className="membership-benefits">
      <h1 className="title">{t('title_membership_benefits')}</h1>

      {/* Benefits Grid Section */}
      <div className="benefits-grid">
        <div className="benefit-item">
          <h2>1</h2>
          <p>{t('tr_pro_benefit*')}</p>
        </div>
        <div className="benefit-item">
          <h2>2</h2>
          <p>{t('tr_pro_benefit_1*')}</p>
        </div>
        <div className="benefit-item">
          <h2>3</h2>
          <p>{t('tr_pro_benefit_2*')}</p>
        </div>
        <div className="benefit-item">
          <h2>4</h2>
          <p>{t('tr_pro_benefit_3*')}</p>
        </div>
        <div className="benefit-item">
          <h2>5</h2>
          <p>{t('tr_pro_benefit_4*')}</p>
        </div>
        <div className="benefit-item">
          <h2>6</h2>
          <p>{t('tr_pro_benefit_5*')}</p>
        </div>
        <div className="benefit-item">
          <h2>7</h2>
          <p>{t('tr_pro_benefit_6*')}</p>
        </div>
      </div>

      {/* Prices Section */}
      <div className="prices-section">
        <div className="price-item">
          <h2>2 {t('tr_fee')} </h2>
          <p>499.000đ</p>
          <p style={{fontSize:"1.5rem"}}>{t('tr_fee*')}</p>
          <p className="gift-text">{t('tr_pro')}</p>
        </div>
        <div className="price-item">
          <h2>3 {t('tr_fee')} </h2>
          <p>199.000đ</p>
          <p style={{fontSize:"1.5rem"}}>{t('tr_fee*')}</p>
          <p className="gift-text">{t('tr_pro')}</p>
        </div>
      </div>
    </div>
  );
};

export default MembershipBenefits;
