import axios from "axios";
import { showToast } from "core/utils/toastUtil";
import { KEY_TOKEN } from "app/const/App";

import {
    ADD_BLOG_REQUEST,
    ADD_BLOG_SUCCESS,
    ADD_BLOG_FAILED,
    GET_DETAIL_BLOG_REQUEST,
    GET_DETAIL_BLOG_SUCCESS,
    GET_DETAIL_BLOG_FAILED,
    GET_NEW_BLOGS_LIST_FAILED,
    GET_NEW_BLOGS_LIST_REQUEST,
    GET_NEW_BLOGS_LIST_SUCCESS,
    GET_4_RECENT_BLOGS_REQUEST,
    GET_4_RECENT_BLOGS_FAILED,
    GET_4_RECENT_BLOGS_SUCCESS,
    GET_BLOGS_BY_TAGS_REQUEST,
    GET_BLOGS_BY_TAGS_SUCCESS,
    GET_BLOGS_BY_TAGS_FAILED,
    DELETE_BLOG_REQUEST,
    DELETE_BLOG_SUCCESS,
    DELETE_BLOG_FAILED,
    UPDATE_BLOG_REQUEST,
    UPDATE_BLOG_SUCCESS,
    UPDATE_BLOG_FAILED,
} from "../constant/newBlogConstant";
import {
    API_ENDPOINT,
    NEW_BLOG,
    CREATE_NEW_BLOG,
    GET_SPECIFIC_BLOG,
    GET_4_RECENT_BLOGS,
    GET_BLOGS_BY_TAGS,
    DELETE_BLOG_BY_ID,
    UPDATE_BLOG_BY_ID
} from "app/const/Api";

export const addBlog = (blogData) => {
    return async (dispatch) => {
        dispatch({
            type: ADD_BLOG_REQUEST,
        });

        try {

            const token = localStorage.getItem(KEY_TOKEN);
            await axios({
                url: API_ENDPOINT + CREATE_NEW_BLOG,
                method: "POST",
                data: blogData,
                headers: { token: token },
            })
                .then((res) => {
                    showToast("success", "Gửi thông tin thành công !", {
                        timeout: 5000,
                    });
                })
                .catch((err) => console.log(err.response.data));

            // const response = await axios.post("/api/blogs", blogData);
            // dispatch({
            //     type: ADD_BLOG_SUCCESS,
            //     payload: response.data,
            // });
        } catch (error) {
            dispatch({
                type: ADD_BLOG_FAILED,
                payload: error.response ? error.response.data : error.message,
            });
        }
    };
};

export const getAllBlogs = () => {
    return async (dispatch) => {
        dispatch({
            type: GET_NEW_BLOGS_LIST_REQUEST,
        });

        try {
            const token = localStorage.getItem(KEY_TOKEN);
            const response = await axios({
                url: API_ENDPOINT + NEW_BLOG,
                method: "GET",
                headers: { token: token },
            });

            dispatch({
                type: GET_NEW_BLOGS_LIST_SUCCESS,
                payload: response.data,
            });

            // showToast("success", "Fetched blogs successfully!", {
            //     timeout: 5000,
            // });
        } catch (error) {
            dispatch({
                type: GET_NEW_BLOGS_LIST_FAILED,
                payload: error.response ? error.response.data : error.message,
            });

            // showToast("error", "Failed to fetch blogs!", {
            //     timeout: 5000,
            // });
        }
    };
};

export const getSpecificBlog = (blogId) => {
    return async (dispatch) => {
        dispatch({
            type: GET_DETAIL_BLOG_REQUEST,
        });

        try {
            const token = localStorage.getItem(KEY_TOKEN);
            const response = await axios({
                url: API_ENDPOINT + GET_SPECIFIC_BLOG + blogId,
                method: "GET",
                headers: { token: token },
            });

            dispatch({
                type: GET_DETAIL_BLOG_SUCCESS,
                payload: response.data, // Assuming response.data contains the specific blog
            });

            // showToast("success", "Fetched blog successfully!", {
            //     timeout: 5000,
            // });
        } catch (error) {
            dispatch({
                type: GET_DETAIL_BLOG_FAILED,
                payload: error.response ? error.response.data : error.message,
            });

            // showToast("error", "Failed to fetch blog!", {
            //     timeout: 5000,
            // });
        }
    };
};

export const getRecentBlogs = () => {
    return async (dispatch) => {
        dispatch({
            type: GET_4_RECENT_BLOGS_REQUEST,
        });
        try {
            const token = localStorage.getItem(KEY_TOKEN);
            const response = await axios({
                url: API_ENDPOINT + GET_4_RECENT_BLOGS,
                method: "GET",
                headers: { token: token }
            })

            dispatch({
                type: GET_4_RECENT_BLOGS_SUCCESS,
                payload: response.data,
            });

            // showToast("success", "Fetched recent blogs successfully!", {
            //     timeout: 5000,
            // });
        } catch (error) {
            dispatch({
                type: GET_4_RECENT_BLOGS_FAILED,
                payload: error,
            });

            // showToast("error", "Failed to fetch recent blogs!", {
            //     timeout: 5000,
            // });
        }
    };
};

export const getBlogsByTags = (blogTag) => {
    return async (dispatch) => {
        dispatch({
            type: GET_BLOGS_BY_TAGS_REQUEST,
        });
        try {
            const token = localStorage.getItem(KEY_TOKEN);
            const response = await axios({
                url: API_ENDPOINT + GET_BLOGS_BY_TAGS + blogTag,
                method: "GET",
                headers: { token: token }
            })

            dispatch({
                type: GET_BLOGS_BY_TAGS_SUCCESS,
                payload: response.data,
            });

            // showToast("success", "Fetched recent blogs successfully!", {
            //     timeout: 5000,
            // });
        } catch (error) {
            dispatch({
                type: GET_BLOGS_BY_TAGS_FAILED,
                payload: error,
            });

            // showToast("error", "Failed to fetch recent blogs!", {
            //     timeout: 5000,
            // });
        }
    };
};

// Delete Blog Action
export const deleteBlog = (blogId) => {
    return async (dispatch) => {
        dispatch({
            type: DELETE_BLOG_REQUEST,
        });

        try {
            const token = localStorage.getItem(KEY_TOKEN);
            const response = await axios({
                url: API_ENDPOINT + DELETE_BLOG_BY_ID + blogId, // Replace with the correct endpoint for deleting a blog
                method: "DELETE",
                headers: { token: token },
            });

            // Dispatch the success action and pass the deleted blog ID
            dispatch({
                type: DELETE_BLOG_SUCCESS,
                payload: blogId, // Send the blog ID to be removed from the state
            });

            showToast("success", "Blog deleted successfully!", {
                timeout: 5000,
            });
        } catch (error) {
            dispatch({
                type: DELETE_BLOG_FAILED,
                payload: error.response ? error.response.data : error.message,
            });

            showToast("error", "Failed to delete blog!", {
                timeout: 5000,
            });
        }
    };
};

export const updateBlog = (blogId, updatedBlogData) => async (dispatch) => {
    dispatch({ type: UPDATE_BLOG_REQUEST });
    try {
        const token = localStorage.getItem(KEY_TOKEN);
        const response = await axios({
            url: API_ENDPOINT + UPDATE_BLOG_BY_ID + blogId,
            method: "PUT",
            headers: { token },
            data: updatedBlogData,
        });

        dispatch({ type: UPDATE_BLOG_SUCCESS, payload: response.data });

        showToast("success", "Blog updated successfully!", { timeout: 5000 });
    } catch (error) {
        dispatch({
            type: UPDATE_BLOG_FAILED,
            payload: error.response ? error.response.data : error.message,
        });

        showToast("error", "Failed to update blog!", { timeout: 5000 });
    }
};