import React from 'react';
import { Link } from 'react-router-dom';
const NewsCarouseCard = ({ title, image, id }) => {
    return (
        <div style={{
            background: '#009A26',
            borderRadius: '8px',
            overflow: 'hidden',
            textAlign: 'center',
            color: 'white',
            padding: '10px',
            margin: '0 15px',
        }}>
            <img
                src={image}
                alt={title}
                style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    display: 'block',
                }}
            />
            <h3 style={{
                margin: '15px 0 10px',
                fontSize: '18px',
                fontWeight: 'bold',
            }}>{title}</h3>
            <Link
                to={`/tin-tuc/${id}`} // Add your desired route here
                id={id} // Add the ID
                style={{
                    padding: '10px 20px',
                    backgroundColor: '#fff',
                    color: 'green',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    textDecoration: 'none', // Remove underline
                    display: 'inline-block', // Make it inline-block
                    transition: 'background-color 0.3s',
                }}
                onMouseEnter={(e) => e.target.style.backgroundColor = '#ddd'}
                onMouseLeave={(e) => e.target.style.backgroundColor = '#fff'}
            >
                Xem Thêm
            </Link>
        </div>
    );
};

export default NewsCarouseCard;
