import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import WrapperBlogImg from 'share/wrapperBlogImg';
import Slider from "react-slick";
import NewsCarouseCard from 'share/newsCarouselCard';
import "./styles.scss";
import { getAllBlogs } from 'core/redux/actions/newBlogAction';
import { tagsData } from '../const';
import useSiteTitle from 'core/hooks/useSiteTitle';
import { useTranslation } from "react-i18next";
const BlogHomePage = () => {
    const { t } = useTranslation("common");
    useSiteTitle(t("blog_header"));
    const dispatch = useDispatch();
    const blogs = useSelector(state => state.newblog.blogs);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getAllBlogs());
    }, []);
    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const StandoutNews = ({ standoutTitle, blogData }) => {
        const standoutBlogs = blogData
            .filter((item) => item.standOut)  // Filter for standOut blogs
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))  // Sort by createdAt
            .slice(0, 4);
        return (
            <>
                <h1 className='mt-10'>{standoutTitle}</h1>
                <div className="separator-line">
                    <div className="separator-animated"></div>
                </div>
                <div className="blog-content-section">
                    {standoutBlogs.map((item, index) => (
                        <div
                            key={item.id}
                            className={`blog-content-item ${index === 0 ? 'blog-first-item' : 'blog-other-items'}`}
                        >
                            <img src={item.imgBackground} alt={item.title} className="blog-item-image" />
                            <div className="blog-content-info"
                                onClick={index === 0 ? () => window.location.href = `/tin-tuc/${item.id}` : null}
                            >
                                <h2>{item.title}</h2>
                                {/* {item.link && (
                                        <a href={item.link} className="blog-see-more-button">
                                            See More
                                        </a>
                                    )} */}
                                <Link to={`/tin-tuc/${item.id}`} className="blog-see-more-button">
                                    Xem Ngay
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };
    const DailyNews = ({ dailyNewsTitle, blogData }) => {
        const recentBlogs = blogData
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(0, 5);

        return (
            <>
                <h1 className='mt-10'>{dailyNewsTitle}</h1>
                <div className="separator-line">
                    <div className="separator-animated"></div>
                </div>
                <div style={{ marginTop: '20px', marginBottom: '30px' }}>
                    <Slider {...carouselSettings}>
                        {recentBlogs.map((card, index) => (
                            <NewsCarouseCard
                                key={index}
                                title={card.title}
                                image={card.imgBackground}
                                id={card.id}
                            />
                        ))}
                    </Slider>
                </div>
            </>
        );
    };
    return (
        <div className='home-page'>
            <LazyLoad offset={100}>
                <div className="services">
                    <div className="row">
                        <WrapperBlogImg
                            blogData={blogs}
                            tags={tagsData}
                        />
                        <StandoutNews standoutTitle="TIN TỨC NỔI BẬT" blogData={blogs} />
                        <DailyNews dailyNewsTitle="TIN TỨC MỖI NGÀY" blogData={blogs} />
                    </div>
                </div>
            </LazyLoad>
        </div>
    );
};

export default BlogHomePage;