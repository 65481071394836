import React, { useEffect, useRef, useState } from "react";
import useSiteTitle from "core/hooks/useSiteTitle";
import LPEModal from "app/components/modal";

import RoadMap from "assets/images/source/life-map/map3.jpg";
import RoadMapMobile from "assets/images/source/life-map/map3.jpg";

import {
  big_island,
  small_island,
  big_island_mobile,
  small_island_mobile,
} from "./const";

import "./styles/styles.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

function LifeMap() {
  useSiteTitle("life_map");
  const { t } = useTranslation("common");
  const refModal = useRef(null);
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setInterval(() => {
      setLoading(false);
    }, 3000);

    return () => {
      clearInterval(timeout);
    };
  }, []);
  console.log("big_island", big_island);
  const handleOpenModal = (content) => {
    refModal.current.handleOpen();
    setContent(content);
  };
  return (
    <LazyLoad>
      <div className="lpe-lifemap">
        <div className="lpe-lifemap_wrapper">
          <img
            className="img-fluid "
            src={RoadMap}
            alt={RoadMap}
            style={{ width: "100%" }}
          />

          {big_island?.map((island, index) => {
            return (
              <div
                className="lpe-lifemap_big-island"
                key={index}
                style={{
                  top: island.position.x,
                  left: island.position.y,
                }}
              >
                <div className="lpe-lifemap_big-island_outside">
                  <div className={island.className}>
                    <img src={island.island} alt={island.island} />
                    {island.mascot && (
                      <img
                        src={island.mascot}
                        alt={island.mascot}
                        className={island.mascotClassName}
                      />
                    )}
                    <div className="lpe-lifemap_big-island_inside">
                      <img
                        src={island.sign}
                        alt={island.sign}
                        style={{
                          width: "20%",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          cursor: "pointer",
                          animation: "movingSign 1s linear infinite",
                        }}
                        className="big_sign"
                      />
                      <div className={`big_popup ${island.contentPosition}`}>
                        {island.detail.map((text) => {
                          return (
                            <>
                              {text.type === "heading" && (
                                <h4>{t(text.text)}</h4>
                              )}
                              {text.type === "description" && (
                                <h6>{text.text}</h6>
                              )}
                              {text.type === "text" && <p>{text.text}</p>}
                            </>
                          );
                        })}

                        {island.link && <Link to={island.link}>Xem thêm</Link>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {small_island?.map((island, index) => {
            return (
              <div
                className="lpe-lifemap_small-island"
                key={index}
                style={{
                  top: island.position.x,
                  left: island.position.y,
                }}
              >
                <div className="lpe-lifemap_small-island_outside">
                  <div className={island.className}>
                    <img
                      src={island.island}
                      alt={island.island}
                      className="img-fluid"
                    />
                    {island.mascot && (
                      <img
                        src={island.mascot}
                        alt={island.mascot}
                        className={island.mascotClassName}
                      />
                    )}
                    <div className="lpe-lifemap_small-island_inside">
                      <img
                        src={island.sign}
                        alt={island.sign}
                        style={{
                          width: "43%",
                          position: "absolute",
                          top: "50%",
                          left: "60%",
                          transform: "translate(-50%, -50%)",
                          cursor: "pointer",
                          animation: "movingSign 1s linear infinite",
                        }}
                        className="img-fluid small_sign"
                      />

                      <div className={`small_popup ${island.contentPosition}`}>
                        {island.detail.map((text) => {
                          return (
                            <>
                              {text.type === "heading" && <h4>{text.text}</h4>}
                              {text.type === "description" && (
                                <h6>{text.text}</h6>
                              )}
                              {text.type === "text" && <p>{text.text}</p>}
                            </>
                          );
                        })}

                        {island.link && <Link to={island.link}>Xem thêm</Link>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="lpe-lifemap-mobile">
        <div className="lpe-lifemap_wrapper">
          <img
            className="img-fluid "
            src={RoadMapMobile}
            alt={RoadMapMobile}
            style={{ height: "2500px" }}
          />

          {big_island_mobile?.map((island, index) => {
            return (
              <div
                className="lpe-lifemap_big-island"
                key={index}
                style={{
                  top: island.position.x,
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <div
                  className="lpe-lifemap_big-island_outside"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className={island.className}>
                    <img src={island.island} alt={island.island} />
                    {island.mascot && (
                      <img
                        src={island.mascot}
                        alt={island.mascot}
                        className={island.mascotClassName}
                      />
                    )}
                    <div className="lpe-lifemap_big-island_inside">
                      <img
                        src={island.sign}
                        alt={island.sign}
                        style={{
                          width: "20%",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          cursor: "pointer",
                          animation: "movingSign 1s linear infinite",
                        }}
                        onClick={() => {
                          handleOpenModal(island.detail);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {small_island_mobile?.map((island, index) => {
            return (
              <div
                className="lpe-lifemap_small-island"
                key={index}
                style={{
                  top: island.position.x,
                  left: island.position.y,
                }}
              >
                <div className="lpe-lifemap_small-island_outside">
                  <div className={island.className}>
                    <img src={island.island} alt={island.island} />
                    {island.mascot && (
                      <img
                        src={island.mascot}
                        alt={island.mascot}
                        className={island.mascotClassName}
                      />
                    )}
                    <div className="lpe-lifemap_small-island_inside">
                      <img
                        src={island.sign}
                        alt={island.sign}
                        style={{
                          width: "43%",
                          position: "absolute",
                          top: "50%",
                          left: "85%",
                          transform: "translate(-50%, -50%)",
                          cursor: "pointer",
                          animation: "movingSign 1s linear infinite",
                        }}
                        onClick={() => {
                          handleOpenModal(island.detail);
                        }}
                      />

                      <div className={`small_popup ${island.contentPosition}`}>
                        <p>Pop up for content</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <LPEModal ref={refModal} width="90%">
        <div className="mobile-popup-modal">
          {content?.map((text) => {
            return (
              <>
                {text.type === "heading" && <h4>{t(text.text)}</h4>}
                {text.type === "description" && <h6>{text.text}</h6>}
                {text.type === "text" && <p>{text.text}</p>}

                {text.type === "link" && <Link to={text.link}>Xem thêm</Link>}
              </>
            );
          })}
        </div>
      </LPEModal>
    </LazyLoad>
  );
}

export default LifeMap;
