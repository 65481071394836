import React from "react";
import "./styles.scss"; 
import membership_1 from "assets/images/source/events/membership-1.jpg";
import membership_2 from "assets/images/source/events/membership-2.jpg";
import membership_3 from "assets/images/source/events/membership-3.jpg";
import membership_4 from "assets/images/source/events/membership-4.jpg";
import membership_5 from "assets/images/source/events/membership-5.jpg";
import membership_6 from "assets/images/source/events/membership-6.jpg";

import TimeTable from "../timetable";
import { membershipOverviewData } from "../const";
import FlagMembership from "share/flagmembership";
import SeeMoreButton from "share/seemorebutton";

function highlightFansipanKinabalu(contentItem) {
  if (contentItem.includes("Chinh phục đỉnh Fansipan")) {
    return <span className="fansipan-text">Chinh phục đỉnh Fansipan</span>;
  }
  if (contentItem.includes("Chinh phục đỉnh Kinabalu")) {
    return <span className="kinabalu-text">Chinh phục đỉnh Kinabalu</span>;
  }
  return contentItem;
}

const MembershipOverview = () => {
  return (
    <div className="membership-overview">
      {membershipOverviewData.map((item) => (
        <div key={item.id} className="membership-card">
          {/* Section Title */}
          {item.title && (
            <div className="membership-card-title">
              <h1 className="membership-card-title-text">{item.title}</h1>
            </div>
          )}

          {/* Small Title */}
          {item.smallTitle && (
            <div className="membership-card-small-title">
              <h2>{item.smallTitle}</h2>
            </div>
          )}

          {/* "Reminder" Section */}
          {item.reminder?.length > 0 && (
            <div className="membership-card-reminder">
              {item.reminder.map((note, idx) => (
                <p key={idx} className="reminder-text">
                  {note}
                </p>
              ))}
            </div>
          )}


          {/* Challenge Section (Fansipan/Kinabalu) */}
          {item.challenge?.length > 0 && (
            <div className="membership-card-challenge">
              {item.challenge.map((challengeItem, idx) => (
                <p key={idx} className="challenge-line">
                  {highlightFansipanKinabalu(challengeItem)}
                </p>
              ))}
            </div>
          )}

          {/* Main Content */}
          {item.content?.length > 0 && (
            <div className="membership-card-content">
              {item.content.map((contentItem, index) => (
                <p key={index}>
                  <span className="number-circle">{index + 1}</span>
                  <span className="content-text">
                    {highlightFansipanKinabalu(contentItem)}
                  </span>
                </p>
              ))}
            </div>
          )}

          {/* Highlight Section */}
          {item.highlight?.length > 0 && (
            <div className="membership-card-highlight">
              {item.highlight.map((highlightItem, index3) => (
                <p key={index3}>
                  <span className="number-circle">{index3 + 1}</span>
                  <span className="highlight-text">{highlightItem}</span>
                </p>
              ))}
            </div>
          )}

          {/* Coaching images (item.id === 2) */}
          {item.id === 2 && (
            <div className="membership-images">
              <img src={membership_5} alt="Coaching session 1" />
              <img src={membership_6} alt="Coaching session 2" />
            </div>
          )}

          {/* Flags + 4 images (item.id === 4) */}
          {item.id === 4 && (
            <>
              <FlagMembership />
              <div className="membership-images">
                <img src={membership_1} alt="Event 1" />
                <img src={membership_2} alt="Event 2" />
                <img src={membership_3} alt="Event 3" />
                <img src={membership_4} alt="Event 4" />
              </div>
            </>
          )}

          {/* Conditionally render "See More" link for id=1 or id=3 */}
          <div className="see-more-button-container">
            {item.id === 1 && (
              <a
                href="https://theoriginsacademy.vn/tin-tuc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SeeMoreButton />
              </a>
            )}
           {item.id === 2 ? (
              <div className="membership-card-special">
                {item.special.map((specialItem, index2) => {
                  return (
                    <div key={index2} className="coach-block">
                      {/* Coach's description (e.g. "Cô La Hạ Giang Thanh - 12 giờ Coaching") */}
                      <p>
                        <span className="content-text">{specialItem}</span>
                      </p>

                      {/* Insert a small timeline or time slots UI */}
                      <TimeTable coachName={specialItem} />
                    </div>
                  );
                })}
              </div>
            ) : (
              /* For all other sections, render them as before */
              item.special?.length > 0 && (
                <div className="membership-card-special">
                  {item.special.map((specialItem, index2) => (
                    <p key={index2}>
                      <span className="content-text">{specialItem}</span>
                    </p>
                  ))}
                </div>
              )
            )}


            {item.id === 3 && (
              <a
                href="https://theoriginsacademy.vn/chinh-phuc-fansipan"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SeeMoreButton />
              </a>
            )}
            {item.id === 4 && (
              <a
                href="https://theoriginsacademy.vn/the-restart"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SeeMoreButton />
              </a>
            )}
            {item.id === 5 && (
              <a
                href="https://theoriginsacademy.vn/tin-tuc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SeeMoreButton />
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MembershipOverview;
