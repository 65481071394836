import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from 'react-router-dom';
import "./styles.scss";
import { tagsData } from "../const";
import { getSpecificBlog, getRecentBlogs } from "core/redux/actions/newBlogAction";
import useSiteTitle from 'core/hooks/useSiteTitle';
import { useTranslation } from "react-i18next";
import Loading from "share/loading";
const BlogDetailPage = () => {
    const { t } = useTranslation("common");
    useSiteTitle(t("blog_header"));
    const dispatch = useDispatch();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getSpecificBlog(id));
        dispatch(getRecentBlogs());
    }, [dispatch, id]);
    const specificBlog = useSelector(state => state.newblog.specificBlog);
    const recentBlogs = useSelector(state => state.newblog.recentBlogs);

    useEffect(() => {
        if (specificBlog && recentBlogs?.length > 0) {
            setLoading(false);
        }
    }, [specificBlog, recentBlogs]);

    if (loading) {
        return <Loading />;
    }
    const BlogDetailHeader = () => {
        return (
            <div
                style={{
                    position: "relative",
                    height: "400px",
                    width: "100%",
                    backgroundImage: `url(${specificBlog.imgBackground})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div className="title-box">

                    <div className="separator-line-detail">
                        <div className="separator-animated"></div>
                    </div>
                    <h1>{specificBlog.title}</h1>
                </div>
            </div>
        );
    }
    const LeftSectionBody = () => {
        return (
            <div className="col" style={{ textAlign: "center" }}>
                <h3 style={{ color: "blue", justifySelf: "center", alignSelf: "center", marginTop: "10px" }}>TIN TỨC MỖI NGÀY</h3>
                <div className="separator-line-detail" style={{ width: "80%", height: "5px" }}>
                    <div className="separator-animated"></div>
                </div>
                <div className="card-column">
                    {recentBlogs.slice(0, 3).map((card) => (
                        <div key={card.id} className="custom-card">
                            <img src={card.imgBackground} alt={card.title} className="blog-detail-item-image" />
                            <div className="blog-detail-left-custom-container">
                                <h5 style={{ alignSelf: "flex-start" }}>{card.title}</h5>
                                <Link to={`/tin-tuc/${card.id}`} className="blog-detail-see-more-button">
                                    Xem Ngay
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>

                <h3 style={{ color: "blue", justifySelf: "center", alignSelf: "center", marginTop: "30px" }}>DANH MỤC</h3>
                <div className="separator-line-detail" style={{ width: "80%", height: "5px" }}>
                    <div className="separator-animated"></div>
                </div>
                {tagsData.map((tag, index) => (
                    <Link
                        key={index}
                        to={`/tin-tuc/tag/${index}`}  // Adjust this to your route structure
                        className="blog-detail-tag-container"
                    >
                        {tag}
                    </Link>
                ))}
            </div>
        );
    };

    const RightSectionBody = () => {
        return (
            <div className="blog-detail-right-custom-container">
                {specificBlog.content.map((section, index) => {
                    // Function to validate hex color and return black if invalid
                    const getColor = (hex) => /^#([0-9A-F]{3}){1,2}$/i.test(hex) ? hex : "#000000";

                    switch (section.type) {
                        case "heading":
                            return (
                                <h1
                                    key={index}
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "15px",
                                        color: getColor(section.color)  // Apply validated color
                                    }}
                                >
                                    {section.content}
                                </h1>
                            );

                        case "heading2":
                            return (
                                <h2
                                    key={index}
                                    style={{
                                        marginTop: "8px",
                                        marginBottom: "12px",
                                        color: getColor(section.color)  // Apply validated color
                                    }}
                                >
                                    {section.content}
                                </h2>
                            );

                        case "heading3":
                            return (
                                <h3
                                    key={index}
                                    style={{
                                        marginTop: "6px",
                                        marginBottom: "10px",
                                        color: getColor(section.color)  // Apply validated color
                                    }}
                                >
                                    {section.content}
                                </h3>
                            );

                        case "text":
                            // Regular expression to detect URLs and convert them into clickable links
                            const urlRegex = /(https?:\/\/[^\s]+)/g;
                            const processedText = section.content.split(urlRegex).map((part, i) =>
                                urlRegex.test(part) ? (
                                    <a
                                        key={i}
                                        href={part}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: getColor(section.color) }} // Apply validated color
                                    >
                                        {part}
                                    </a>
                                ) : (
                                    part
                                )
                            );
                            return (
                                <div
                                    key={index}
                                    style={{
                                        width: "100%",
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        color: getColor(section.color)  // Apply validated color
                                    }}
                                >
                                    {processedText}
                                </div>
                            );

                        case "imgNote":
                            return (
                                <div
                                    key={index}
                                    style={{
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        fontWeight: "lighter",
                                        alignSelf: "center",
                                        fontStyle: "italic",
                                        color: getColor(section.color)  // Apply validated color
                                    }}
                                >
                                    {section.content}
                                </div>
                            );

                        case "image":
                            return (
                                <img
                                    key={index}
                                    src={section.content}
                                    alt={`Image for section ${index + 1}`}
                                    className="blog-detail-image"
                                    style={{ alignSelf: "center" }}
                                />
                            );

                        default:
                            return null;
                    }
                })}
            </div>

        );
    }

    const BlogDetailBody = () => {
        return (
            <>
                <div className="split-screen-container mt-10">
                    <div className="left-section">
                        <LeftSectionBody />
                    </div>
                    <div className="vertical-separator" />
                    <div className="right-section">
                        <RightSectionBody />
                    </div>

                </div>
            </>
        );
    }
    return (
        <div>
            {(BlogDetailHeader())}
            {(BlogDetailBody())}
        </div>
    );
};

export default BlogDetailPage;
