import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import { tagsData } from "app/modules/ClientModules/const";

function WrapperBlogImg({ blogData = [], tags = tagsData }) {
    // Function to get a random blog from the list
    const getRandomBlog = (blogs) => {
        if (blogs.length === 0) return null;
        const randomIndex = Math.floor(Math.random() * blogs.length);
        return blogs[randomIndex];
    };

    // Group blogs by tags and select one random blog for each tag
    const groupedBlogs = tags.map((_, index) => {
        const blogsForTag = blogData.filter((blog) => blog.tags.includes(index));
        return getRandomBlog(blogsForTag);
    });

    return (
        <div>
            <div className="wrapperBlogImg">
                {groupedBlogs.map((blog, index) => {
                    if (!blog) return null; // Skip if no blog matches the tag
                    return (
                        <Link
                            className={`gridItem${index + 1}`}
                            key={index}
                            to={`/tin-tuc/tag/${index}`}
                        >
                            <img
                                src={blog.imgBackground}
                                alt="Error"
                                style={{ objectFit: "cover" }}
                            />
                            <p className="wrapperTitle">{tagsData[index]}</p>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}

export default WrapperBlogImg;
