import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { isEmpty } from "lodash";
import Loading from "share/loading";
import { RenderCourseOffline, RenderCourseOnline } from "./renderCourse";
import ZaloImg from "assets/images/ZALO.png";
import FBImg from "assets/images/facebook.jpeg";
import axios from "axios";

function SuccessTransaction({ order }) {
  const [data, setData] = useState(order);
  const [showSuccessDialog, setShowSuccessDialog] = useState(true);
  const navigate = useNavigate();
  const storedParams = localStorage.getItem("utm");
  const parsedParams = JSON.parse(storedParams);
  console.log(data);

  useEffect(() => {
    setData(order);
    sendPaidData(order);
    const timeout = setTimeout(() => {
      setShowSuccessDialog(false);
      navigate("/");
    }, 10000);

    return () => clearTimeout(timeout);
  }, [order, navigate]);

  const SocialCard = ({ imgSrc, title, text, buttonText, url }) => (
    <div className="d-flex w-100 px-10">
      <div className="card-social">
        <img src={imgSrc} className="card-img-top" alt="Card cap" style={{ width: '80%', height: 'auto' }} />
        <div className="card-body" style={{ padding: '0.5rem' }}>
          <h4 className="card-title">{title}</h4>
          <p className="card-text" style={{ color: 'grey', marginTop: '5px' }}>{text}</p>
          <button className="btn btn-primary" style={{ width: '100%' }} onClick={() => handleSocialClick(url)}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
  const sendPaidData = async (data) => {
    if (!data.product || !data.product[0]) {
      console.error("Invalid product data");
      return; // exit the function early if the product array is empty or undefined
    }

    const temp = data.product[0];
    //add tags if bought
    switch (temp.course) {
      case "tr":
        temp["tags"] = ["Vas-TheFirstStep"];
        break;
      case "sm":
        temp["tags"] = ["sm"];
        break;
      case "um":
        temp["tags"] = ["um"];
        break;
      case "act":
        temp["tags"] = ["act"];
        break;
      case "icpc":
        temp["tags"] = ["icpc"];
        break;
      case "imcpc":
        temp["tags"] = ["imcpc"];
        break;
      case "gol":
        temp["tags"] = ["gol"];
        break;
      case "unicorn":
        temp["tags"] = ["combo_unicorn"];
        break;
      case "phoenix":
        temp["tags"] = ["combo_phoenix"];
        break;
      case "dragon":
        temp["tags"] = ["combo_dragon"];
        break;
      default:
        temp["tags"] = ["test"];
        break;
    }

    temp["import_by_tag_name"] = true;

    const finalData = {
      ...temp,
      ...temp.user,
      ...parsedParams,
    };
    console.log("finalData", finalData);
    await axios({
      method: "POST",
      url: "https://api.service.ladiflow.com/1.0/customer/create-or-update",
      headers: {
        "Api-Key": "a21928473afdf60440c8adddec916036aac285ce560b0133",
      },
      data: finalData,
    })
      .then((response) => {
        console.log("response : ", response);
      })
      .catch((err) => {
        console.log("err : ", err);
      });

  }
  const handleSocialClick = (url) => {
    window.open(url, "_blank");
  };

  const renderCourse = (product, index) => {
    if (product.type === "courseOffline") {
      return <RenderCourseOffline product={product} vnpayReturn key={index} />;
    } else if (product.type === "courseOnline") {
      return <RenderCourseOnline product={product} vnpayReturn key={index} />;
    }
    return null;
  };

  return isEmpty(data) ? (
    <Loading />
  ) : (
    <div className="wrapper-content container mb-5 mt-5">
      <div className="product">
        <div className="d-flex justify-content-center">
          <i className="fal fa-check"></i>
        </div>
        <div className="content-body_product">
          <h3 className="mt-4">Thanh toán thành công</h3>
        </div>
      </div>

      <div className="row mt-5">
        <div className="detail-info col-8 offset-2">
          <h3 className="d-flex justify-content-center">Thông tin thanh toán</h3>
          {data?.product?.map(renderCourse)}
        </div>
      </div>

      <div className="container d-flex flex-column align-items-center mt-5">
        <div className="col-9 d-flex justify-content-center">
          <div className="row d-flex w-100" style={{ backgroundColor: "blue", alignItems: "center", height: "100%" }}>
            <p className="m-0 text-center" style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white", flex: 1, padding: "5px" }}>
              Hãy cùng tham gia nhóm cùng Vasanth Gopalan
            </p>
          </div>
        </div>

        <div className="container-social mt-10 d-flex">
          <SocialCard
            imgSrc={FBImg}
            title="Tham gia cộng đồng để cập nhật thông tin giá trị từ Chuyên gia"
            text="Nhóm Riêng Tư: 153.493 thành viên"
            buttonText="Tham Gia Nhóm"
            url="https://www.facebook.com/theoriginsacademy"
          />
          <SocialCard
            imgSrc={ZaloImg}
            title="Tham gia nhóm zalo để đăng ký và cập nhật thông tin"
            text="Nhóm Riêng Tư: 47.369 thành viên"
            buttonText="Tham Gia Nhóm"
            url="https://zalo.me/g/bqufiq446"
          />
        </div>
      </div>
    </div>
  );
}

export default SuccessTransaction;
